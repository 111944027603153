import { defineStore } from 'pinia';
import { shallowReactive } from 'vue';
import { getAffinities } from '@/model/dn-affinity.js';
import { getBreakSettings } from '@/model/dn-break-settings.js';
import { getLanguageCode } from '@/i18n.js';
import { BackOfficeSkills } from '@/model/dn-bo.js';
import { getCallCenters } from '@/model/dn-callcenter.js';
import { getCallGroups } from '@/model/dn-callgroup.js';
import { getDayTypes } from '@/model/dn-daytype.js';
import { EmployeeCallcenters } from '@/model/dn-employee-cc.js';
import { getInitialData } from "@/model/dn-initial-data.js";
import { getCurrentCCId, removeCurrentCCId, setCurrentCCId, removeLoginInfo } from '@/js/dn-localStorage.js';
import { getInitialDateInterval } from "@/js/dn-schedule-helper.js";
import { GENERAL_SETTINGS, GENERAL_EMPAPP_SETTINGS, getSettings, postSettings, getHolidays } from '@/model/dn-setting.js';
import { getRelevantWorstInvoiceStatus } from '@/model/dn-invoice.js';
import { getSwitches } from '@/model/dn-switch.js';
import { convertTagsFromAPI, getTags, getTagUsage } from '@/model/dn-tag.js';
import { deleteTaskType, patchTaskType, postTaskType, TaskTypes } from '@/model/dn-tasktype.js';
import { getCurrentUser, getUsers } from '@/model/dn-user.js';
import { useForecastStore } from '@/stores/forecastStore.js';
import { useReportStore } from "@/stores/reportStore.js";

export const useDataStore = defineStore('data', {
  state: () => ({
    adherenceOptions: { adherenceBase: 0, graceMinutes: 3, adherenceLimit: 90, unScheduledBreakMinutes:20, closeGaps:3 },
    /** @type {import("@/model/dn-affinity.js").Affinity[]} */
    affinities:null,
    backOfficeSkills: shallowReactive(new BackOfficeSkills()),
    /** @type {import("@/model/dn-break-settings.js").BreakSettings[]} */
    breakSettings: null,
    /** @type {import("@/model/dn-callcenter").CallCenter[]} */
    callCenters: null,
    /** @type {import("@/model/dn-callgroup.js").CallGroup[]} */
    callGroups: [],
    /** @type {import("@/model/dn-callcenter").CallCenter} */
    currentCC: null,
    /** @type {import("@/model/dn-user.js").LoggedInUser} */
    currentUser: null,
    /** @type {Date} */
    dateNow: null,
    /** @type {import("@/model/dn-daytype").DayType[]} */
    dayTypes: null,
    employeeCallcenters: shallowReactive(new EmployeeCallcenters()),
    generalEmpAppSettings: {
      publishingWeekDay: 0,
      publishingNumberOfDays: 7,
      allRequestsDisabled: false,
      publishingFixedDate: null,
      requestClosedNumberOfDays: 2,
      requestvaccationClosedNumberOfDays: 60,
      sickRequestDisabled: false,
      tradeDisabled: false,
      hideTeamSchedule: false,
      showOnlyOwnTeam: false,
      weekStartDay: 0,
    },
    generalSettingsLoaded: false,
    /** @type {string} */
    language: null,
    refreshedDueToError: false,
    relevantInvoiceStatus:0,
    lastRelevantInvoiceStatuscheck:new Date("1970-01-01"),
    /** @type {readonly import("@/model/dn-switch.js").SwitchConfig[]} */
    switches: null,
    /**  @type {Map<number, import("@/model/dn-tag.js").Tag>} */
    tagById: null,
    /** @type {number[][]} */
    tagUsage: null,
    taskTypes: shallowReactive(new TaskTypes()),
    userGuide: {
      display: false,
    },
    /** @type {import("@/model/dn-user.js").User[]} */
    users: null,
    /** @type {import("@/model/dn-setting.js").HolidaySettings[]} */
    holidays: null,
  }),
  getters: {
    getMode(){
      //0 for normal, 1 for basic
      if(this.currentUser == null) { return null; }
      
        return this.currentUser.useMode;
    },
    empTabs() {
      if (this.getMode === 0) {
        //full func
        return {
        general: 0,
        skillsMatrix: 1,
        rotations:2,
        availability: 3,
        shifts:4
        };
      } else{
        //basic
        return {
        general: 0,
        rotations:1,
        availability: 2
        };
      }
    },
    callCentersAllowed() {
      const callCenters = this.callCenters;
      if (this.currentUser === null || callCenters === null) { return []; }
      const idList = this.currentUser.contactcenterIds;
      if (idList.length === 0) { return callCenters; }
      return callCenters.filter(c => idList.includes(c.id));
    },
    /** @returns {import("@/model/dn-callgroup.js").CallGroup[]} */
    callGroupsAllowed() {
      const currentUser = this.currentUser;
      if (currentUser === null) { return []; }
      if (currentUser.contactcenterIds.length === 0) {
        // @ts-ignore
        return this.callGroups;
      }
      // @ts-ignore
      return this.callGroups.filter(x => currentUser.contactcenterIds.includes(x.contactCenterId));
    },
    /** @returns {import("@/model/dn-callgroup.js").CallGroup[]} */
    callGroupsCurrentCC() {
      const currentCC = this.currentCC;
      if (currentCC === null) { return []; }
      return this.callGroups.filter(x => x.contactCenterId === currentCC.id);
    },
    skillsCurrentCC() {
      const currentCC = this.currentCC;
      /** @type {import("@/model/dn-skill.js").Skill[]} */
      const skills = [];
      if (currentCC && this.backOfficeSkills.isLoaded) {
        const ccId = currentCC.id;
        for (const skill of this.callGroups) {
          if (skill.contactCenterId === ccId) {
            skills.push(skill);
          }
        }
        for (const skill of this.backOfficeSkills.createList([ccId])) {
          skills.push(skill);
        }
      }
      return skills;
    },
    /** @returns {import("@/model/dn-daytype").DayType} */
    dayTypeDefault() {
      return this.dayTypes !== null ? this.dayTypes[0] : undefined;
    },
    latestImportDate() {
      const switches = this.switches;
      if (switches === null) { return null; }
      let dt = new Date();
      for (let i = 0; i < switches.length; i++) {
        if (switches[i].lastTime) {
          if (switches[i].lastTime < dt) { dt = new Date(switches[i].lastTime) }
        }
      }
      return dt;
    },
    /** @returns {import("@/model/dn-tasktype.js").TaskType[]} */
    taskTypeList() {
      return this.taskTypes.list;
    },
    /** @returns {Map<number,import("@/model/dn-tasktype.js").TaskType>} */
    taskTypeMap() {
      return this.taskTypes.byId;
    },
  },
  actions: {
    async init() {
      const initialData = await getInitialData();
      this.employeeCallcenters.init(initialData.employeeCallcenters);
      this.taskTypes.init(initialData.tasktypes);
      this.tagById = convertTagsFromAPI(initialData.tags);
    },
    async loadAffinities() {
      this.affinities = await getAffinities();
    },
    async loadBreakSettings() {
      this.breakSettings = await getBreakSettings();
    },
    async loadCallCenters() {
      this.callCenters = await getCallCenters();
      const currentUser = this.currentUser;
      let currentCCId;
      if (currentUser.employee) {
        currentCCId = currentUser.employee.ccid;
      } else {
        currentCCId = getCurrentCCId();
      }

      const cc = this.callCenters.find(x => x.id == currentCCId);
      this.currentCC = cc ? cc : null;
    },
    async loadHolidays() {
      this.holidays = await getHolidays();
    },
    async loadRelevantInvoiceStatus() {  
      this.relevantInvoiceStatus = await getRelevantWorstInvoiceStatus();
      this.lastRelevantInvoiceStatuscheck=new Date()
    },
    logOut() {
      removeLoginInfo();
      removeCurrentCCId();
      this.currentUser = null;
    },
    /**
     * @param {import("@/model/dn-callcenter").CallCenter} cc
     */
    setCurrentCC(cc) {
      this.currentCC = cc;
      setCurrentCCId(cc.id);
    },
    async loadCallGroups() {
      this.callGroups = await getCallGroups();
    },
    async loadCurrentUser() {
      this.currentUser = await getCurrentUser();
    },
    loadDateNow() {
      this.dateNow = getInitialDateInterval()[0];
    },
    async loadDayTypes() {
      this.dayTypes = await getDayTypes();
    },
    loadLanguage() {
      this.language = getLanguageCode();
    },
    async loadGeneralSettings() {
      const forecastStore = useForecastStore();
      const forecastOptions = forecastStore.forecastOptions;
      const adherenceOptions = this.adherenceOptions;
      const reportStore = useReportStore();
      const reportOptions = reportStore.reportOptions;
      const generalEmpAppSettings = this.generalEmpAppSettings;
      const rows = await getSettings();
      for (const r of rows) {
        switch (r.key) {
          case GENERAL_SETTINGS.historicWeeks:
            forecastOptions.historicWeeks = r.value;
            break;
          case GENERAL_SETTINGS.forecastWeeks:
            forecastOptions.forecastWeeks = r.value;
            break;
          case GENERAL_SETTINGS.numberOfHistoricSpecialDays:
            forecastOptions.numberOfHistoricSpecialDays = r.value;
            break;
          case GENERAL_SETTINGS.occupacyFte:
            forecastOptions.occupacyFte = r.value;
            break;
          case GENERAL_SETTINGS.adherancePercent:
            forecastOptions.adherancePercent = r.value;
            break;
          case GENERAL_SETTINGS.percentOfAbandonsToInclude:
            forecastOptions.percentOfAbandonsToInclude = r.value;
            break;
          case GENERAL_SETTINGS.adherenceBase:
            adherenceOptions.adherenceBase = r.value;
            break;
          case GENERAL_SETTINGS.graceMinutes:
            adherenceOptions.graceMinutes = r.value;
            break;
          case GENERAL_SETTINGS.unScheduledBreakMinutes:
            adherenceOptions.unScheduledBreakMinutes = r.value;
            break;
            //
          case GENERAL_SETTINGS.closeGaps:
            adherenceOptions.closeGaps = r.value;
            break;
          case GENERAL_SETTINGS.adherenceLimit:
            adherenceOptions.adherenceLimit = r.value;
            break;
          case GENERAL_SETTINGS.reportCalendarDays:
            reportOptions.reportCalendarDays = r.value !== 0;
            break;
          case GENERAL_SETTINGS.numHoursWeeklyOvertime:
            reportOptions.numHoursWeeklyOvertime = r.value;
            break;
          case GENERAL_SETTINGS.weekendAsHoliday:
            reportOptions.weekendAsHoliday = r.value !== 0;
            break;            
          case GENERAL_EMPAPP_SETTINGS.publishingWeekDay:
            generalEmpAppSettings.publishingWeekDay = r.value;
            break;
          case GENERAL_EMPAPP_SETTINGS.publishingNumberOfDays:
            generalEmpAppSettings.publishingNumberOfDays = r.value;
            break;
          case GENERAL_EMPAPP_SETTINGS.publishingFixedDateMs:
            if(r.value){
              generalEmpAppSettings.publishingFixedDate = new Date(r.value*1000);
            }
            break;
          case GENERAL_EMPAPP_SETTINGS.requestClosedNumberOfDays:
            generalEmpAppSettings.requestClosedNumberOfDays = r.value;
            break;
          case GENERAL_EMPAPP_SETTINGS.allRequestsDisabled:
            generalEmpAppSettings.allRequestsDisabled = r.value > 0;
            break;
          case GENERAL_EMPAPP_SETTINGS.requestvaccationClosedNumberOfDays:
            generalEmpAppSettings.requestvaccationClosedNumberOfDays = r.value;
            break;
          case GENERAL_EMPAPP_SETTINGS.sickRequestDisabled:
            generalEmpAppSettings.sickRequestDisabled = r.value > 0;
            break;
          case GENERAL_EMPAPP_SETTINGS.tradeDisabled:
            generalEmpAppSettings.tradeDisabled = r.value > 0;
            break;
            //ADDED
          case GENERAL_EMPAPP_SETTINGS.hideTeamSchedule:
            generalEmpAppSettings.hideTeamSchedule = r.value > 0;
            break;
          case GENERAL_EMPAPP_SETTINGS.showOnlyOwnTeam:
            generalEmpAppSettings.showOnlyOwnTeam = r.value > 0;
            break;
          case GENERAL_EMPAPP_SETTINGS.weekStartDay:
            generalEmpAppSettings.weekStartDay = r.value;
            break;  
        }
      }
      this.generalSettingsLoaded = true;
    },
    async loadSwitches() {
      const switches = await getSwitches();
      this.switches = Object.freeze(switches);
    },
    async loadTags() {
      const tags = await getTags();
      this.tagById = convertTagsFromAPI(tags);
    },
    async loadTagUsage() {
      this.tagUsage = await getTagUsage();
    },
    async loadTaskTypes() {
      await this.taskTypes.load();
    },
    async initUsers() {
      if (this.users !== null) { return; }
      this.users = await getUsers();
    },
    /**
     * @param {import("@/model/dn-setting").GeneralEmpAppSettings} generalEmpAppSettings
     */
    async saveGeneralEmpAppSettings(generalEmpAppSettings) {
      const storedSettings = this.generalEmpAppSettings;
      /** @type {import("@/model/dn-setting.js").SettingDto[]} */
      const data = [];

      if (generalEmpAppSettings.hasChanges) {
        if (generalEmpAppSettings.publishingWeekDay != storedSettings.publishingWeekDay) {
          storedSettings.publishingWeekDay = generalEmpAppSettings.publishingWeekDay
          data.push({ key: GENERAL_EMPAPP_SETTINGS.publishingWeekDay, value: generalEmpAppSettings.publishingWeekDay });
        }
        if (generalEmpAppSettings.publishingNumberOfDays != storedSettings.publishingNumberOfDays) {
          storedSettings.publishingNumberOfDays = generalEmpAppSettings.publishingNumberOfDays
          data.push({ key: GENERAL_EMPAPP_SETTINGS.publishingNumberOfDays, value: generalEmpAppSettings.publishingNumberOfDays });
        }
        const publishingFixedDate = generalEmpAppSettings.usePublishFixedDate ? generalEmpAppSettings.publishingFixedDate : null;
        if (publishingFixedDate !== storedSettings.publishingFixedDate) {
          storedSettings.publishingFixedDate = publishingFixedDate;
          let value =null
          if(publishingFixedDate){value=Math.round(generalEmpAppSettings.publishingFixedDate.getTime()/1000)}
          data.push({ key: GENERAL_EMPAPP_SETTINGS.publishingFixedDateMs, value: value });
        }
        if (generalEmpAppSettings.allRequestsDisabled != storedSettings.allRequestsDisabled) {
          storedSettings.allRequestsDisabled = generalEmpAppSettings.allRequestsDisabled;
          const x = generalEmpAppSettings.allRequestsDisabled ? 1 : 0;
          data.push({ key: GENERAL_EMPAPP_SETTINGS.allRequestsDisabled, value: x });
        }
        if (generalEmpAppSettings.requestClosedNumberOfDays != storedSettings.requestClosedNumberOfDays) {
          storedSettings.requestClosedNumberOfDays = generalEmpAppSettings.requestClosedNumberOfDays
          data.push({ key: GENERAL_EMPAPP_SETTINGS.requestClosedNumberOfDays, value: generalEmpAppSettings.requestClosedNumberOfDays });
        }
        if (generalEmpAppSettings.requestvaccationClosedNumberOfDays != storedSettings.requestvaccationClosedNumberOfDays) {
          storedSettings.requestvaccationClosedNumberOfDays = generalEmpAppSettings.requestvaccationClosedNumberOfDays
          data.push({ key: GENERAL_EMPAPP_SETTINGS.requestvaccationClosedNumberOfDays, value: generalEmpAppSettings.requestvaccationClosedNumberOfDays });
        }
        if (generalEmpAppSettings.sickRequestDisabled != storedSettings.sickRequestDisabled) {
          storedSettings.sickRequestDisabled = generalEmpAppSettings.sickRequestDisabled;
          const x = generalEmpAppSettings.sickRequestDisabled ? 1 : 0;
          data.push({ key: GENERAL_EMPAPP_SETTINGS.sickRequestDisabled, value: x });
        }
        if (generalEmpAppSettings.tradeDisabled != storedSettings.tradeDisabled) {
          storedSettings.tradeDisabled = generalEmpAppSettings.tradeDisabled;
          const x = generalEmpAppSettings.tradeDisabled ? 1 : 0;
          data.push({ key: GENERAL_EMPAPP_SETTINGS.tradeDisabled, value: x });
        }
        //ADDED
        if (generalEmpAppSettings.hideTeamSchedule != storedSettings.hideTeamSchedule) {
          storedSettings.hideTeamSchedule = generalEmpAppSettings.hideTeamSchedule;
          const x = generalEmpAppSettings.hideTeamSchedule ? 1 : 0;
          data.push({ key: GENERAL_EMPAPP_SETTINGS.hideTeamSchedule, value: x });
        }  
        if (generalEmpAppSettings.showOnlyOwnTeam != storedSettings.showOnlyOwnTeam) {
          storedSettings.showOnlyOwnTeam = generalEmpAppSettings.showOnlyOwnTeam;
          const x = generalEmpAppSettings.showOnlyOwnTeam ? 1 : 0;
          data.push({ key: GENERAL_EMPAPP_SETTINGS.showOnlyOwnTeam, value: x });
        }  
     
        if (generalEmpAppSettings.weekStartDay != storedSettings.weekStartDay) {
          storedSettings.weekStartDay = generalEmpAppSettings.weekStartDay
          data.push({ key: GENERAL_EMPAPP_SETTINGS.weekStartDay, value: generalEmpAppSettings.weekStartDay });
        }

        generalEmpAppSettings.confirmChanges();
      }
      await postSettings(data);
    },
    async saveGeneralSettings(generalOptions) {
      const forecastStore = useForecastStore();
      const storedForecastSettings = forecastStore.forecastOptions;
      const storedAdherenceSettings = this.adherenceOptions;
      /** @type {import("@/model/dn-setting.js").SettingDto[]} */
      const data = [];
      let needsReloadOfForecast = generalOptions.hasChangesForecast

      if (generalOptions.hasChanges) {
        if (generalOptions.historicWeeks != storedForecastSettings.historicWeeks) {
          storedForecastSettings.historicWeeks = generalOptions.historicWeeks
          data.push({ key: GENERAL_SETTINGS.historicWeeks, value: generalOptions.historicWeeks });
        }
        if (generalOptions.forecastWeeks != storedForecastSettings.forecastWeeks) {
          storedForecastSettings.forecastWeeks = generalOptions.forecastWeeks
          data.push({ key: GENERAL_SETTINGS.forecastWeeks, value: generalOptions.forecastWeeks });
        }
        if (generalOptions.numberOfHistoricSpecialDays != storedForecastSettings.numberOfHistoricSpecialDays) {
          storedForecastSettings.numberOfHistoricSpecialDays = generalOptions.numberOfHistoricSpecialDays
          data.push({ key: GENERAL_SETTINGS.numberOfHistoricSpecialDays, value: generalOptions.numberOfHistoricSpecialDays });
        }
        if (generalOptions.occupacyFte != storedForecastSettings.occupacyFte) {
          storedForecastSettings.occupacyFte = generalOptions.occupacyFte
          data.push({ key: GENERAL_SETTINGS.occupacyFte, value: generalOptions.occupacyFte });
        }
        if (generalOptions.adherancePercent != storedForecastSettings.adherancePercent) {
          storedForecastSettings.adherancePercent = generalOptions.adherancePercent
          data.push({ key: GENERAL_SETTINGS.adherancePercent, value: generalOptions.adherancePercent });
        }
        if (generalOptions.percentOfAbandonsToInclude != storedForecastSettings.percentOfAbandonsToInclude) {
          storedForecastSettings.percentOfAbandonsToInclude = generalOptions.percentOfAbandonsToInclude
          data.push({ key: GENERAL_SETTINGS.percentOfAbandonsToInclude, value: generalOptions.percentOfAbandonsToInclude });
        }
        if (generalOptions.adherenceLimit != storedAdherenceSettings.adherenceLimit) {
          storedAdherenceSettings.adherenceLimit = generalOptions.adherenceLimit
          data.push({ key: GENERAL_SETTINGS.adherenceLimit, value: generalOptions.adherenceLimit });
        }
        if (generalOptions.graceMinutes != storedAdherenceSettings.graceMinutes) {
          storedAdherenceSettings.graceMinutes = generalOptions.graceMinutes
          data.push({ key: GENERAL_SETTINGS.graceMinutes, value: generalOptions.graceMinutes });
        }
        if (generalOptions.unScheduledBreakMinutes != storedAdherenceSettings.unScheduledBreakMinutes) {
          storedAdherenceSettings.unScheduledBreakMinutes = generalOptions.unScheduledBreakMinutes
          data.push({ key: GENERAL_SETTINGS.unScheduledBreakMinutes, value: generalOptions.unScheduledBreakMinutes });
        }
        if (generalOptions.closeGaps != storedAdherenceSettings.closeGaps) {
          storedAdherenceSettings.closeGaps = generalOptions.closeGaps
          data.push({ key: GENERAL_SETTINGS.closeGaps, value: generalOptions.closeGaps });
        }
        if (generalOptions.adherenceBase != storedAdherenceSettings.adherenceBase) {
          storedAdherenceSettings.adherenceBase = generalOptions.adherenceBase
          data.push({ key: GENERAL_SETTINGS.adherenceBase, value: generalOptions.adherenceBase });
        }
        generalOptions.confirmChanges();
      }

      await postSettings(data);
      if (needsReloadOfForecast) {
        forecastStore.reloadForecastAndHistoricData();
      }
      return;

    },
    /**
     * @param {import("@/model/dn-tasktype.js").TaskType[]} tasktypes
     */
    async saveTaskTypes(tasktypes) {
      let itemsToDelete = []
      let changeTaskTypes = []
      let promises = []
      for (let i = 0; i < tasktypes.length; i++) {
        if (tasktypes[i].isToDelete) {
          promises.push(deleteTaskType(tasktypes[i]))
          changeTaskTypes.push(tasktypes[i])
          itemsToDelete.push(tasktypes[i])
        } else if (tasktypes[i].hasChanges) {
          if (tasktypes[i].id == -1) {
            promises.push(postTaskType(tasktypes[i]))
            changeTaskTypes.push(tasktypes[i])
          }
          else {
            promises.push(patchTaskType(tasktypes[i]))
            changeTaskTypes.push(tasktypes[i])
          }
        }
      }
      for (let i = 0; i < itemsToDelete.length; i++) {
        var index = tasktypes.indexOf(itemsToDelete[i]);
        tasktypes.splice(index, 1);
      }

      const values = await Promise.all(promises);
      for (let i = 0; i < changeTaskTypes.length; i++) {
        if (changeTaskTypes[i].id == -1) {
          changeTaskTypes[i].id = values[i].id
        }
        changeTaskTypes[i].confirmChanges();
      }

      await this.loadTaskTypes();
    },
  }
});
